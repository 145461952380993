// ** Auth Endpoints
export default {
  // loginEndpoint: 'http://localhost:8001/api/login',
   loginEndpoint: 'http://testeapi.examecompra.com.br/api/login', //HOMOLOGAÇÃO
  // loginEndpoint: 'https://api.examecompra.com.br/api/login', //PRODUÇÃO
  //loginEndpoint: 'http://localhost:8001/login', //LOCAL

  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // baseApi: 'http://localhost:8001/', //LOCAL
   baseApi: 'http://testeapi.examecompra.com.br/', //HOMOLOGAÇÃO
  //  baseApi: 'https://api.examecompra.com.br:21027/', //PRODUCAO

  // apiEndpoint: 'http://localhost:8001/api', 
   apiEndpoint: 'http://testeapi.examecompra.com.br/api', //HOMOLOGAÇÃO
  //  apiEndpoint: 'https://api.examecompra.com.br/api', //PRODUÇÃO
  //apiEndpoint: 'http://localhost:8001', //LOCAL

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'sessionToken',
  storageRefreshTokenKeyName: 'sessionTokenexpiration'
}
